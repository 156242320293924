import Auth from '@/components/pages/Auth'
import Error404 from '@/components/pages/Error404'
import MessageRedirect from '@/components/pages/Redirect'
import ClientList from '@/components/pages/client/ClientList'
import FavoritesList from '@/components/pages/other/FavoritesList'

import LeadsList from '@/components/pages/lead/LeadsList'
import LeadOferta from '@/components/pages/lead/LeadOferta'
import LeadProductSingle from '@/components/pages/lead/LeadProductSingle'
import LeadOfertaCartList from '@/components/pages/lead/LeadOfertaCartList'
import LeadCreate from '@/components/pages/lead/LeadCreate'

import OfertasHome from '@/components/pages/ofertas/OfertasHome'
import OfertasList from '@/components/pages/ofertas/OfertasList'
import OfertaSingle from '@/components/pages/ofertas/OfertaSingle'
import OfertasValidar from '@/components/pages/ofertas/OfertasValidar'
import OfertasValidarSingle from '@/components/pages/ofertas/OfertasValidarSingle'

// import InformesList from '@/components/pages/informes/InformesList'
import Perfil from '@/components/pages/user/Perfil'
import Helpmessage from '@/components/pages/user/Helpmessage'
// import CentrosList from '@/components/pages/client/centros/CentrosList'
// import CentroEdit from '@/components/pages/client/centros/CentroEdit'
import CatalogoList from '@/components/pages/client/catalogo/CatalogoList'
import ProductSingle from '@/components/pages/client/catalogo/ProductSingle'
import CartList from '@/components/pages/client/catalogo/CartList'

// Pedidos
import PedidosHome from '@/components/pages/pedidos/PedidosHome'
import PedidosComprar from '@/components/pages/pedidos/PedidosComprar'
import PedidosList from '@/components/pages/pedidos/PedidosList'
import PedidoSingle from '@/components/pages/pedidos/PedidoSingle'
import PedidosValidar from '@/components/pages/pedidos/PedidosValidar'
import PedidosValidarSingle from '@/components/pages/pedidos/PedidosValidarSingle'

import AlbaranesList from '@/components/pages/client/albaranes/AlbaranesList'
import AlbaranSingle from '@/components/pages/client/albaranes/AlbaranSingle'

import FacturasList from '@/components/pages/client/facturas/FacturasList'
import FacturaSingle from '@/components/pages/client/facturas/FacturaSingle'

import InmportacionPedidos from '@/components/pages/client/importacion/InmportacionPedidos'

// Estadisticas cliente
import EstadisticasCliente from '@/components/pages/client/estadisticas/EstadisticasCliente'
import EstadisticasCompras from '@/components/pages/client/estadisticas/EstadisticasCompras'
import EstadisticasCentros from '@/components/pages/client/estadisticas/EstadisticasCentros'
import EstadisticasCategorias from '@/components/pages/client/estadisticas/EstadisticasCategorias'
import EstadisticasArticulos from '@/components/pages/client/estadisticas/EstadisticasArticulos'
// import RacionalizacionProducto from '@/components/pages/client/estadisticas/RacionalizacionProducto'
// import RacionalizacionTotal from '@/components/pages/client/estadisticas/RacionalizacionTotal'

// import PlantillasList from '@/components/pages/client/plantillas/PlantillasList'

// CATALOG GENERATOR
import CatalogGeneratorList from '@/components/pages/catalog-generator/CatalogGeneratorList'
import CatalogGeneratorSingle from '@/components/pages/catalog-generator/CatalogGeneratorSingle'

// Dashboard
import Dasboard from '@/components/pages/dasboard/Dasboard'
import DashboardResumen from '@/components/pages/dasboard/DashboardResumen'
import DashboardPedidos from '@/components/pages/dasboard/DashboardPedidos'
import DashboardProveedores from '@/components/pages/dasboard/DashboardProveedores'
import DashboardOfertas from '@/components/pages/dasboard/DashboardOfertas'

import DashboardAlbaranes from '@/components/pages/dasboard/DashboardAlbaranes'
import DashboardAlbaranesResumen from '@/components/pages/dasboard/albaranes/DashboardAlbaranesResumen'
import DashboardAlbaranesRanking from '@/components/pages/dasboard/albaranes/DashboardAlbaranesRanking'
import DashboardAlbaranesRankingCenter from '@/components/pages/dasboard/albaranes/DashboardAlbaranesRankingCenter'

import DashboardVentas from '@/components/pages/dasboard/DashboardVentas'
import DashboardVentasResumen from '@/components/pages/dasboard/ventas/DashboardVentasResumen'
import DashboardVentasRanking from '@/components/pages/dasboard/ventas/DashboardVentasRanking'
import DashboardVentasRankingCenter from '@/components/pages/dasboard/ventas/DashboardVentasRankingCenter'
import DashboardVentasSinActividad from '@/components/pages/dasboard/ventas/DashboardVentasSinActividad'
import DashboardVentasEficiencia from '@/components/pages/dasboard/ventas/DashboardVentasEficiencia'
import DashboardVentasPerdidos from '@/components/pages/dasboard/ventas/DashboardVentasPerdidos'
import DashboardVentasPerdidosCenter from '@/components/pages/dasboard/ventas/DashboardVentasPerdidosCenter'

import CarbonFootprint from '@/components/pages/carbon-footprint/Main'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Auth,
    meta: {Auth: false}
  },
  {
    path: '/clientes',
    name: 'clientList',
    component: ClientList,
    meta: {Auth: true}
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: Perfil,
    meta: {Auth: true}
  },
  {
    path: '/helpmessage',
    name: 'helpmessage',
    component: Helpmessage,
    meta: {Auth: true}
  },
  {
    path: '/catalogo-publico',
    name: 'catalogoListPublic',
    component: MessageRedirect,
    props: true,
    meta: {Auth: false}
  },
  {
    path: '/catalogo/:sup?/',
    name: 'catalogoList',
    component: CatalogoList,
    props: true,
    meta: {Auth: true},
    children: [
      {
        path: 'product/:id/',
        name: 'productSingle',
        component: ProductSingle,
        props: true,
        meta: {Auth: true}
      },
      {
        path: 's',
        name: 'catalogoSearch',
        // component: CatalogoList,
        props: true,
        meta: {Auth: true}
      }
    ]
  },
  {
    path: '/carrito',
    name: 'cartList',
    component: CartList,
    meta: {Auth: true}
  },
  {
    path: '/favorites-list',
    name: 'favoritesList',
    component: FavoritesList,
    meta: {Auth: true}
  },
  {
    path: '/pedidos',
    redirect: '/pedidos/comprar',
    name: 'pedidosHome',
    component: PedidosHome,
    meta: {Auth: true},
    children: [
      {
        path: 'comprar',
        name: 'pedidosComprar',
        component: PedidosComprar,
        props: true,
        meta: {Auth: true},
        children: [
          {
            path: 's',
            name: 'pedidosComprarSearch',
            props: true,
            meta: {Auth: true}
          }
        ]
      },
      {
        path: 'mis-pedidos',
        name: 'pedidosList',
        component: PedidosList,
        meta: {Auth: true},
        children: [
          {
            path: 'pedido/:id/',
            name: 'pedidoSingle',
            component: PedidoSingle,
            props: true,
            meta: {Auth: true}
          }
        ]
      },
      {
        path: 'validar-pedidos',
        // redirect: 'validar-pedidos/pendientes',
        name: 'validarPedidos',
        component: PedidosValidar,
        meta: {Auth: true},
        children: [
          {
            path: 'pedido/:id/',
            name: 'validarPedidoSingle',
            component: PedidosValidarSingle,
            props: true,
            meta: {Auth: true}
          }
        ]
      },
      {
        path: 'albaranes',
        name: 'albaranesList',
        component: AlbaranesList,
        meta: {Auth: true},
        children: [
          {
            path: 'albaran/:id/',
            name: 'albaranSingle',
            component: AlbaranSingle,
            props: true,
            meta: {Auth: true}
          }
        ]
      },
      {
        path: 'facturas',
        name: 'facturasList',
        component: FacturasList,
        meta: {Auth: true},
        children: [
          {
            path: 'factura/:id/',
            name: 'facturaSingle',
            component: FacturaSingle,
            props: true,
            meta: {Auth: true}
          }
        ]
      },
      {
        path: 'importacion-pedidos',
        name: 'importacionPedidos',
        component: InmportacionPedidos,
        meta: {Auth: true}
      }
    ]
  },
  {
    path: '/catalogos',
    name: 'catalogGeneratorList',
    component: CatalogGeneratorList,
    children: [
      {
        path: 'catalogo/:id/',
        name: 'catalogGeneratorSingle',
        component: CatalogGeneratorSingle,
        props: true,
        meta: {Auth: true}
      }
    ]
  },
  {
    path: '/carbonfootprint',
    name: 'carbonFootprint',
    component: CarbonFootprint
  },
  {
    path: '/estadisticas',
    name: 'estadisticas',
    component: EstadisticasCliente,
    meta: {Auth: true},
    redirect: {name: 'estadisticasCompras'},
    children: [
      {
        path: 'estadisticas-compras',
        name: 'estadisticasCompras',
        component: EstadisticasCompras,
        props: true,
        meta: {Auth: true}
      },
      {
        path: 'estadisticas-centros',
        name: 'estadisticasCentros',
        component: EstadisticasCentros,
        props: true,
        meta: {Auth: true}
      },
      {
        path: 'estadisticas-categorias',
        name: 'estadisticasCategorias',
        component: EstadisticasCategorias,
        props: true,
        meta: {Auth: true}
      },
      {
        path: 'estadisticas-articulos',
        name: 'estadisticasArticulos',
        component: EstadisticasArticulos,
        props: true,
        meta: {Auth: true}
      }
    ]
  },
  {
    path: '/leads',
    name: 'leadsList',
    component: LeadsList,
    meta: {Auth: true},
    children: [
      {
        path: 'lead-oferta',
        name: 'leadOferta',
        component: LeadOferta,
        props: true,
        meta: {Auth: true},
        children: [
          {
            path: 'product/:id/',
            name: 'leadProductSingle',
            component: LeadProductSingle,
            props: true,
            meta: {Auth: true}
          },
          {
            path: 's',
            name: 'leadSearch',
            // component: CatalogoList,
            props: true,
            meta: {Auth: true}
          }
        ]
      },
      {
        path: 'lead-oferta-carrito',
        name: 'leadOfertaCartList',
        component: LeadOfertaCartList,
        meta: {Auth: true}
      },
      {
        path: 'lead-create',
        name: 'leadCreate',
        component: LeadCreate,
        meta: {Auth: true}
      }
    ]
  },
  {
    path: '/ofertas',
    redirect: '/ofertas/listado',
    name: 'ofertasHome',
    component: OfertasHome,
    meta: {Auth: true},
    children: [
      {
        path: 'listado',
        name: 'ofertasList',
        component: OfertasList,
        // props: true,
        meta: {Auth: true},
        children: [
          {
            path: ':id/',
            name: 'ofertaSingle',
            component: OfertaSingle,
            props: true,
            meta: {Auth: true}
          }
        ]
      },
      {
        path: 'validar-ofertas',
        name: 'validarOfertas',
        component: OfertasValidar,
        meta: {Auth: true},
        children: [
          {
            path: 'oferta/:id/',
            name: 'validarOfertasSingle',
            component: OfertasValidarSingle,
            props: true,
            meta: {Auth: true}
          }
        ]
      }
    ]
  },
  {
    path: '/dashboard',
    redirect: 'dashboard/resumen',
    name: 'dashboard',
    component: Dasboard,
    meta: {Auth: true},
    children: [
      {
        path: 'resumen',
        name: 'dashboardResumen',
        component: DashboardResumen,
        props: true,
        meta: {Auth: true}
      },
      {
        path: 'pedidos',
        name: 'dashboardPedidos',
        component: DashboardPedidos,
        props: true,
        meta: {Auth: true}
      },
      {
        path: 'albaranes',
        redirect: 'albaranes/resumen',
        name: 'dashboardAlbaranes',
        component: DashboardAlbaranes,
        props: true,
        meta: {Auth: true},
        children: [
          {
            path: 'resumen',
            name: 'dashboardAlbaranesResumen',
            component: DashboardAlbaranesResumen,
            props: true,
            meta: {Auth: true}
          },
          {
            path: 'ranking',
            name: 'dashboardAlbaranesRanking',
            component: DashboardAlbaranesRanking,
            props: true,
            meta: {Auth: true},
            children: [
              {
                path: 'center/:id/',
                name: 'dashboardAlbaranesRankingCenter',
                component: DashboardAlbaranesRankingCenter,
                props: true,
                meta: {Auth: true}
              }
            ]
          }
        ]
      },
      {
        path: 'ventas',
        redirect: 'ventas/resumen',
        name: 'dashboardVentas',
        component: DashboardVentas,
        props: true,
        meta: {Auth: true},
        children: [
          {
            path: 'resumen',
            name: 'dashboardVentasResumen',
            component: DashboardVentasResumen,
            props: true,
            meta: {Auth: true}
          },
          {
            path: 'ranking',
            name: 'dashboardVentasRanking',
            component: DashboardVentasRanking,
            props: true,
            meta: {Auth: true},
            children: [
              {
                path: 'center/:id/',
                name: 'dashboardVentasRankingCenter',
                component: DashboardVentasRankingCenter,
                props: true,
                meta: {Auth: true}
              }
            ]
          },
          {
            path: 'sin-actividad',
            name: 'dashboardVentasSinActividad',
            component: DashboardVentasSinActividad,
            props: true,
            meta: {Auth: true}
          },
          {
            path: 'eficiencia',
            name: 'dashboardVentasEficiencia',
            component: DashboardVentasEficiencia,
            props: true,
            meta: {Auth: true}
          },
          {
            path: 'perdidos',
            name: 'dashboardVentasPerdidos',
            component: DashboardVentasPerdidos,
            props: true,
            meta: {Auth: true},
            children: [
              {
                path: 'center/:id/',
                name: 'dashboardVentasPerdidosCenter',
                component: DashboardVentasPerdidosCenter,
                props: true,
                meta: {Auth: true}
              }
            ]
          }
        ]
      },
      {
        path: 'proveedores',
        name: 'dashboardProveedores',
        component: DashboardProveedores,
        props: true,
        meta: {Auth: true}
      },
      {
        path: 'ofertas',
        name: 'dashboardOfertas',
        component: DashboardOfertas,
        props: true,
        meta: {Auth: true}
      }
    ]
  },
  {
    path: '/404',
    name: 'error404',
    component: Error404,
    meta: {Auth: false}
  },
  {
    path: '*',
    redirect: '/404'
  }
]

export default routes
