// Importamos mutaciones
import * as types from '@/store/mutation-types'

const catalogue = {
  state: {
    migasSlug: null, // dónde guardaremos los ID de las categorias el primero es el nivel 0 ,etc... ej: 864-109-391
    supplier: null, // donde guardaremos el ID del proveedor seleccionado. 0 => todos
    supplierImageBanner: null,
    pageCurrent: 1, // página por la que vamos en el catálogo.,
    cancelAxios: null,
    showInfoSoste: false,
    showOnDemand: false,
    suppliersBanner: [],
    suppliers: null
  },
  actions: {
    setCatalogueMigasSlug ({commit}, data) {
      commit(types.UPDATE_CATALOGUE_MIGAS_SLUG, data)
    },
    setCatalogueSupplier ({commit}, data) {
      commit(types.UPDATE_CATALOGUE_SUPPLIER, data)
    },
    setCatalogueSuppliers ({commit}, data) {
      commit(types.SET_SUPPLIERS, data)
    },
    setCatalogueSupplierImageBanner ({commit}, data) {
      commit(types.UPDATE_CATALOGUE_SUPPLIER_IMAGE_BANNER, data)
    },
    setCataloguePageCurrent ({commit}, data) {
      commit(types.UPDATE_CATALOGUE_PAGE_CURRENT, data)
    }
  },
  mutations: {
    [types.UPDATE_CATALOGUE_MIGAS_SLUG] (state, data) {
      state.migasSlug = data
    },
    [types.UPDATE_CATALOGUE_SUPPLIER] (state, data) {
      state.supplier = data
    },
    [types.SET_SUPPLIERS_BANNERS] (state, data) {
      state.suppliersBanner = data
    },
    [types.SET_SUPPLIERS] (state, data) {
      state.suppliers = data
    },
    [types.UPDATE_CATALOGUE_SUPPLIER_IMAGE_BANNER] (state, data) {
      state.supplierImageBanner = data
    },    
    [types.UPDATE_CATALOGUE_PAGE_CURRENT] (state, data) {
      state.pageCurrent = data
    },
    [types.CANCEL_AXIOS] (state, data) {
      state.cancelAxios = data
    },
    [types.MODAL_INFO_SOSTE_VIEW] (state, data) {
      state.showInfoSoste = data
    },
    [types.MODAL_ON_DEMAND] (state, data) {
      state.showOnDemand = data
    }
  },
  getters: {
    catalogueMigasSlug: state => {
      return state.migasSlug
    },
    catalogueSupplier: state => {
      return state.supplier
    },
    catalogueSuppliers: state => {
      return state.suppliers
    },
    catalogueSuppliersBanner: state => {
      return state.suppliersBanner
    },
    catalogueSupplierImageBanner: state => {
      return state.supplierImageBanner
    },
    cataloguePageCurrent: state => {
      return state.pageCurrent
    },
    getCancelAxios: state => {
      return state.cancelAxios
    },
    getShowModalInfoSoste: state => {
      return state.showInfoSoste
    },
    getShowModalOnDemand: state => {
      return state.showOnDemand
    }
  }
}

export default catalogue
